.gm-ui-hover-effect {
    display: none !important;
}

.gm-style-iw-d {
    overflow: hidden !important;
}

.gm-style-iw-ch {
    padding: 0 !important;
}

.gm-style-iw {
    padding: 0 !important;
}
